import fetch from 'auth/FetchInterceptor';
import { higherAuthFunction } from './utils';

const vesselService = {}

// Clients
// Fetch all clients
const getClients = async function (params, cb) {
    try {
    const res = await fetch({
      url: '/vessel/clients/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
  }
  
  // Add a new client
const addClient = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/clients/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a client
const updateClient = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/clients/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a client
const deleteClient = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/clients/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Drivers
// Fetch all drivers
const getDrivers = async function (params, cb) {
  try {
    const res = await  fetch({
      url: '/vessel/drivers/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new driver
const addDriver = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/drivers/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a driver
const updateDriver = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/drivers/${data.get('id')}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a driver
const deleteDriver = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/drivers/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Ports
// Fetch all ports
const getPorts = async function (params,cb) {
    try {
    const res = await fetch({
      url: '/vessel/ports/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new port
const addPort = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/ports/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
    }
  }
  
  // Update a port
const updatePort = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ports/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a port
const deletePort = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ports/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Vehicles
// Fetch all vehicles
const getVehicles = async function (params, cb) {
  try {
    const res = await fetch({
      url: '/vessel/vehicles/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new vehicle
const addVehicle = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/vehicles/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a vehicle
const updateVehicle = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/vehicles/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a vehicle
const deleteVehicle = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/vehicles/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Ship schedule
// Fetch all ship schedules
const getShipSchedules = async function (params,cb) {
    try {
    const res = await fetch({
      url: '/vessel/ship-schedules/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new ship schedule
const addShipSchedule = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/ship-schedules/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a ship schedule
const updateShipSchedule = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ship-schedules/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a ship schedule
const deleteShipSchedule = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ship-schedules/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Get ship schedule by id
const getShipScheduleById = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ship-schedules/${data.id}/`,
        method: 'get',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Ships
// Fetch all ships
const getShips = async function (params, cb) {
    try {
      const res = await fetch({
        url: '/vessel/ships/',
        method: 'get',
        params
      });
      if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new ship
const addShip = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/ships/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a ship
const updateShip = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ships/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a ship
const deleteShip = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ships/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Task Details
// Fetch all task details
const getTaskDetails = async function (params, cb) {
  try {
    const res = await fetch({
      url: '/vessel/task-details/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }

  // Add new task detail
const addTaskDetail = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/task-details/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a task detail
const updateTaskDetail = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/task-details/${data.get('id')}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a task detail
const deleteTaskDetail = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/task-details/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }

// Tasks
// Fetch all tasks
const getTasks = async function (params, cb) {
  try {
    const res = await  fetch({
      url: '/vessel/tasks/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
    
  // Add new task
const addTask = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/tasks/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a task
const updateTask= async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/tasks/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a task
const deleteTask = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/task/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Pointers
// Fetch all pointers
const getPointers = async function (params, cb) {
  try {
    const res = await fetch({
      url: '/vessel/pointers/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new pointer
const addPointer = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/pointers/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a pointer
const updatePointer = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/pointers/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a pointer
const deletePointer = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/pointers/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

  // Products
  // Fetch all products
const getProducts = async function (params, cb) {
    try {
      const res = await fetch({
        url: '/vessel/products/',
        method: 'get',
        params
      });
      if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
  }

  // Add a product
const addProduct = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/products/',
        method: 'post',
        data,
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

  // Update a product
const updateProduct = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/products/${data.id}/`,
        method: 'put',
        data,
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

  // Delete a product
const deleteProduct = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/products/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

  // Clients
vesselService.getClients = (params, cb) => higherAuthFunction(getClients, params, cb);
vesselService.addClient = (data, cb) => higherAuthFunction(addClient, data, cb);
vesselService.updateClient = (data, cb) => higherAuthFunction(updateClient, data, cb);
vesselService.deleteClient = (data, cb) => higherAuthFunction(deleteClient, data, cb);

// Drivers
vesselService.getDrivers = (params, cb) => higherAuthFunction(getDrivers, params, cb);
vesselService.addDriver = (data, cb) => higherAuthFunction(addDriver, data, cb);
vesselService.updateDriver = (data, cb) => higherAuthFunction(updateDriver, data, cb);
vesselService.deleteDriver = (data, cb) => higherAuthFunction(deleteDriver, data, cb);

// Ports
vesselService.getPorts = (params, cb) => higherAuthFunction(getPorts, params, cb);
vesselService.addPort = (data, cb) => higherAuthFunction(addPort, data, cb);
vesselService.updatePort = (data, cb) => higherAuthFunction(updatePort, data, cb);
vesselService.deletePort = (data, cb) => higherAuthFunction(deletePort, data, cb);

// Vehicles
vesselService.getVehicles = (params, cb) => higherAuthFunction(getVehicles, params, cb);
vesselService.addVehicle = (data, cb) => higherAuthFunction(addVehicle, data, cb);
vesselService.updateVehicle = (data, cb) => higherAuthFunction(updateVehicle, data, cb);
vesselService.deleteVehicle = (data, cb) => higherAuthFunction(deleteVehicle, data, cb);

// Ship Schedule
vesselService.getShipSchedules = (params, cb) => higherAuthFunction(getShipSchedules, params, cb);
vesselService.addShipSchedule = (data, cb) => higherAuthFunction(addShipSchedule, data, cb);
vesselService.updateShipSchedule = (data, cb) => higherAuthFunction(updateShipSchedule, data, cb);
vesselService.deleteShipSchedule = (data, cb) => higherAuthFunction(deleteShipSchedule, data, cb);
vesselService.getShipScheduleById = (data, cb) => higherAuthFunction(getShipScheduleById, data, cb);

// Ships
vesselService.getShips = (params, cb) => higherAuthFunction(getShips, params, cb);
vesselService.addShip = (data, cb) => higherAuthFunction(addShip, data, cb);
vesselService.updateShip = (data, cb) => higherAuthFunction(updateShip, data, cb);
vesselService.deleteShip = (data, cb) => higherAuthFunction(deleteShip, data, cb);

// Task Details
vesselService.getTaskDetails = (params, cb) => higherAuthFunction(getTaskDetails, params, cb);
vesselService.addTaskDetail = (data, cb) => higherAuthFunction(addTaskDetail, data, cb);
vesselService.updateTaskDetail = (data, cb) => higherAuthFunction(updateTaskDetail, data, cb);
vesselService.deleteTaskDetail = (data, cb) => higherAuthFunction(deleteTaskDetail, data, cb);

// Tasks
vesselService.getTasks = (params, cb) => higherAuthFunction(getTasks, params, cb);
vesselService.addTask = (data, cb) => higherAuthFunction(addTask, data, cb);
vesselService.updateTask = (data, cb) => higherAuthFunction(updateTask, data, cb);
vesselService.deleteTask = (data, cb) => higherAuthFunction(deleteTask, data, cb);

// Pointers
vesselService.getPointers = (params, cb) => higherAuthFunction(getPointers, params, cb);
vesselService.addPointer = (data, cb) => higherAuthFunction(addPointer, data, cb);
vesselService.updatePointer = (data, cb) => higherAuthFunction(updatePointer, data, cb);
vesselService.deletePointer = (data, cb) => higherAuthFunction(deletePointer, data, cb);

// Products
vesselService.getProducts = (params, cb) => higherAuthFunction(getProducts, params, cb);
vesselService.addProduct = (data, cb) => higherAuthFunction(addProduct, data, cb);
vesselService.updateProduct = (data, cb) => higherAuthFunction(updateProduct, data, cb);
vesselService.deleteProduct = (data, cb) => higherAuthFunction(deleteProduct, data, cb);


export default vesselService